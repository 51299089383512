import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RecommendTop from "../../components/RecommendTop.js";
import RecommendOther from "../../components/RecommendOther.js";

const allRecommendations = require("../recommendations.json");

// mark
const recommendPlaceInfo = allRecommendations[10];

const TestPage = () => {
  return (
    <>
      <Head
        title={`Austin City Tacos Recommends: ` + recommendPlaceInfo.title}
        description={`One of our top recommendations is ${recommendPlaceInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Austin City Tacos Recommends: {recommendPlaceInfo.title}</h1>

              <RecommendTop data={recommendPlaceInfo}></RecommendTop>

              <div className="big-description">
                <p>
                  Oh boy, where do we start when there's so many great things to
                  say? The Vegan Nom offers 100% plant-based/nut-free menu, as
                  well as gluten-free for most items on the menu. The have some
                  of the best hot sauces around: creamy roasted chipolte or
                  salsa verde. Created by Chris Rios in 2011, the attention to
                  detail for the vegan options are impressive.
                </p>
                <p>
                  First up, we tried the "Grilled Avocado Reale" which is
                  grilled avocado, caramelized onions, kale, black beans, verde,
                  and cilantro. Next we ordered the "Gracias Madre" which is
                  housemade chorizo, scrambled tofu, pepper jack and avocado.
                  For non-vegan options, they have a "Birdie Sanders" taco which
                  obviously you have to try. This taco is chicken, pepperjack,
                  cabbage, crema, and avocado.
                </p>
              </div>
            </div>

            <div className="right-sidebar">
              <RecommendOther title={recommendPlaceInfo.title}></RecommendOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestPage;
